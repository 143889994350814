<template>
    <div>
      <v-row class="ma-auto model-header">
          <v-col class="d-flex align-center py-0 pl-0" cols="10">
            <span class="text modal-header-title">Affiliate Business</span>
          </v-col>
          <v-col class="d-flex justify-end py-0 pr-0">
            <v-icon color="darken-1" @click="$emit('closemodal')">mdi-close</v-icon>
          </v-col>
        </v-row>
        <div class="pa-5">
        <v-col class="pb-0 business-col">
            <label class="business-label">Organisation</label>
                <div class="business-tab1">
                    <AutoCompleteField
                      placeholder="Select..."
                      append-icon="mdi-chevron-down"
                      :items="this.affiliatedBusinesses"
                      :value="getAffBusiness"
                      name="businessIds"
                      @change="changeAffBusiness"
                      item-text="name"
                      item-value="id"
                      class="type-selection notranslate"
                    />
                  </div>
        </v-col>
        <v-col class="pb-0 business-col pt-0">
          <label class="business-label">Businesses</label>
          <!-- style="border: 1px solid #dedede;border-radius: 10px;"> -->
                <!-- <v-row style="border-bottom: 1px solid #dedede;">
                  <v-col cols="2"></v-col>
                  <v-col>name</v-col>
                  <v-col>type</v-col>
                </v-row>
                <v-row v-for="item in Businesses" :key="item">
                  <v-col cols="2">
                  <v-checkbox
                    v-model="affiliatedList"
                    multiple
                    :value="item.id"
                    hide-details
                    color="secondary1"
                    class="proficiency-checkbox"
                ></v-checkbox>
                </v-col>
                <v-col>{{ item.name }}</v-col>
                <v-col>{{ item.type }}</v-col>
                </v-row> -->
                <div class="business-tab1">
                    <AutoCompleteField
                      placeholder="Select..."
                      append-icon="mdi-chevron-down"
                      :items="this.Businesses"
                      multiple
                      :value="affiliatedList"
                      @change="changeBusiness"
                      name="businessIds"
                      item-text="name"
                      item-value="id"
                      class="type-selection notranslate"
                    />
                  </div>
        </v-col>
        </div>
        <v-col style="border-top: 1px solid #e0e0e0;" class="buttons">
            <div class="d-flex justify-end">
              <v-btn
                class="closeout ml-2 btn-purple"
                type="submit"
                :loading="loading"
                :disabled="!getAffBusiness.length && !affiliatedList.length"
                @click="submitForm"
              >Add</v-btn>
              </div>
          </v-col>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import AutoCompleteField from '@/components/common/AutoCompleteField';

export default {
  components: {
    AutoCompleteField,
  },
  data() {
    return {
      getAffBusiness: [],
      affiliatedBusinesses: [],
      Businesses: [],
      affiliatedList: [],
      loading: false,
    };
  },
  methods: {
    ...mapActions('user', ['organizationList', 'affiliateBusiness', 'unAffiliateList']),
    changeAffBusiness(val) {
      this.getAffBusiness = val;
    },
    changeBusiness(val) {
      this.affiliatedList = val;
    },
    async submitForm() {
      this.loading = true;
      const payload = {
        organizationId: this.getAffBusiness,
        businessIds: this.affiliatedList,
      };
      const result = await this.affiliateBusiness(payload);
      if (result.success) {
        this.loading = false;
        this.$emit('closemodal');
      }
    },
  },
  async mounted() {
    const businessName = await this.unAffiliateList();
    this.Businesses = _.map(businessName.businesses, (business) => ({
      id: business.id,
      name: `${business.name}`,
    }));
    const organizationName = await this.organizationList();
    this.affiliatedBusinesses = _.map(organizationName.businesses, (business) => ({
      id: business.id,
      name: business.name,
    }));
  },
};
</script>
