import { render, staticRenderFns } from "./ProjectsHeader.vue?vue&type=template&id=0fd7f282&scoped=true"
import script from "./ProjectsHeader.vue?vue&type=script&lang=js"
export * from "./ProjectsHeader.vue?vue&type=script&lang=js"
import style0 from "./ProjectsHeader.vue?vue&type=style&index=0&id=0fd7f282&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fd7f282",
  null
  
)

export default component.exports