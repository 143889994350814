<template>
  <v-form class="add-organization-form" @submit="submitForm">
    <v-container class="pa-0">
      <v-row class="ma-auto title-header">
        <v-col class="d-flex align-center py-0 pl-0" cols=10>
          <span class="text modal-header-title"> {{title}} </span>
        </v-col>
        <v-col class="d-flex justify-end py-0 pr-0">
          <v-icon color="darken-1" @click="$emit('close-modal')">mdi-close</v-icon>
        </v-col>
      </v-row>
      <div class="scollable-div">
        <v-row class="organization-info">
          <v-col cols="12" class="pa-0">
            <div class="organization-fields" style="width: 100%;">
              <label class="text-left font-label">
                {{formType === '1' ? 'Business' : 'Organization'}} Logo
              </label>
              <div class="d-flex justify-center">
                <div class="banner-img">
                  <v-img
                    v-if="project.bannerImage"
                    class="profile-image"
                    :src="getOptimizedS3ImageURL(profilePicture,
                      { width: 250, height: 250 })" alt="Profile"
                  />
                  <div v-else class="profile-image border-dashed"/>
                </div>
              </div>
              <div class="text-center pt-2">
                <Upload
                  id="upload-documents"
                  class="upload-button"
                  mode="justImage"
                  v-model="project.bannerImage"
                  :acceptedFiles="'.jpg,.jpeg,.png'"
                >
                  <span class="update-pic">Update</span>
                </Upload>
              </div>
            </div>
          </v-col>
          <v-col cols="12" class="px-0 pb-0">
            <label class="text-left font-label">
              {{formType === '1' ? 'Business' : 'Organization'}} Name *
            </label>
            <BaseInput
              name="organizationName"
              solo
              dense
              flat
              v-model.trim="project.name"
              :status="errors('name').length ? 'error': 'normal'"
              :error-messages="errors('name')"
              @input="$v.project.name.$touch()"
              @blur="$v.project.name.$touch()"
            />
          </v-col>
          <v-col cols="12" class="pa-0">
            <label class="text-left font-label"> Business Vertical *</label>
            <v-select
              solo
              flat
              class="type-selection mb-n3 notranslate"
              name="businessTypeId"
              :items="businessTypes"
              item-text="name"
              item-value="id"
              v-model="project.businessTypeId"
              :menu-props="{'content-class' : 'notranslate'}"
              :status="errors('businessTypeId').length ? 'error': 'normal'"
              :error-messages="errors('businessTypeId')"
              @input="$v.project.businessTypeId.$touch()"
              @blur="$v.project.businessTypeId.$touch()"
            ></v-select>
          </v-col>
          <!-- <v-col cols="12" class="pa-0">
            <label class="text-left font-label">Subscription Blend *</label>
            <v-select
              solo
              flat
              class="type-selection mb-n3 notranslate"
              name="businessTypeId"
              :items="filteredSubscriptionBlends"
              item-text="name"
              item-value="id"
              v-model="project.subscriptionBlendIds"
              :menu-props="{'content-class' : 'notranslate'}"
              notClearable
              :status="errors('subscriptionBlendIds').length ? 'error': 'normal'"
              :error-messages="errors('subscriptionBlendIds')"
              @input="$v.project.subscriptionBlendIds.$touch()"
              @blur="$v.project.subscriptionBlendIds.$touch()"
            ></v-select>
          </v-col> -->
        </v-row>
        <v-row class="flex-column primary-info">
          <div class="text1">Primary Contact</div>
          <div class="text2">
            {{`This will be the primary owner of the
            ${formType === '1' ? 'business' : 'organization'} account.`}}
          </div>
          <v-row>
            <v-col cols="6" class="pb-0 pr-2">
              <label class="text-left font-label">First Name *</label>
              <BaseInput
                name="firstName"
                solo
                dense
                flat
                :disabled="project.id ? true: false"
                v-model.trim="project.firstName"
                :status="errors('firstName').length ? 'error': 'normal'"
                :error-messages="errors('firstName')"
                @input="$v.project.firstName.$touch()"
                @blur="$v.project.firstName.$touch()"
              />
            </v-col>
            <v-col cols="6" class="pb-0 pl-2">
              <label class="text-left font-label">Last Name *</label>
              <BaseInput
                name="lastName"
                solo
                dense
                flat
                :disabled="project.id ? true: false"
                v-model.trim="project.lastName"
                :status="errors('lastName').length ? 'error': 'normal'"
                :error-messages="errors('lastName')"
                @input="$v.project.lastName.$touch()"
                @blur="$v.project.lastName.$touch()"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pt-0 pb-0">
              <label class="text-left font-label">Email Address*</label>
              <BaseInput
                name="email"
                solo
                dense
                flat
                :disabled="project.id ? true: false"
                v-model.trim="project.email"
                :status="errors('email').length ? 'error': 'normal'"
                :error-messages="errors('email')"
                @input="$v.project.email.$touch()"
                @blur="$v.project.email.$touch()"
              />
            </v-col>
          </v-row>
        </v-row>
        <v-row class="flex-column primary-info">
          <div class="text1 mb-4">Operations Team</div>
          <v-col cols="12" class="pa-0">
            <AutoCompleteField
              :items="projectDependencies.producersList"
              multiple
              label="Content Producer"
              name="producerIds"
              :value="project.producerIds"
              @change="setProducers"
              class="notranslate"
              item-text="label"
              item-value="value"
            />
          </v-col>
          <v-col cols="12" class="pa-0">
            <AutoCompleteField
              :items="projectDependencies.executivesList"
              multiple
              label="Production Manager"
              name="executiveIds"
              :value="project.executiveIds"
              @change="setExecutives"
              class="notranslate"
              item-text="label"
              item-value="value"
            />
          </v-col>
          <v-col cols="12" class="pa-0">
            <AutoCompleteField
              :items="projectDependencies.editorsList"
              multiple
              label="Editor"
              name="editorIds"
              v-model="project.editorIds"
              class="notranslate"
              item-text="label"
              item-value="value"
            />
          </v-col>
          <v-col cols="12" class="pa-0">
            <AutoCompleteField
              :items="contactsList"
              label="Point of Contact"
              name="pointOfContactId"
              :value="JSON.parse(project.pointOfContactId)"
              @change="setContact"
              class="notranslate"
              item-text="label"
              item-value="value"
              notClearable
            />
          </v-col>
          <v-col cols="12" class="pa-0" v-if="projectObj.id">
            <label class="text-left mb-1 font-label"> Status </label>
            <v-select
              solo
              flat
              class="type-selection mb-n3 notranslate"
              :items="statusItems"
              v-model="project.status"
              :menu-props="{'content-class' : 'notranslate'}"
            ></v-select>
          </v-col>
        </v-row>
      </div>
      <v-card-actions>
        <v-btn text class="py-0" color="primaryGray1"  @click="$emit('close-modal')">
          Cancel
        </v-btn>
        <v-btn
          class="py-0 submit btn-purple"
          type="submit"
          :loading="loading"
          :disabled="$v.$dirty && $v.$invalid"
        >
          {{ action }}
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-form>
</template>

<script>
import _ from 'lodash';
import { required, email } from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';
import { getOptimizedS3ImageURL, url } from '@/helpers';
import BaseInput from '@/components/common/BaseInput';
import AutoCompleteField from '@/components/common/AutoCompleteField';
import Upload from '@/components/common/Upload';

export default {
  components: {
    BaseInput,
    AutoCompleteField,
    Upload,
  },
  props: {
    formType: {
      type: String,
      default: '',
    },
    isAdmin: {
      type: Boolean,
      default() {
        return false;
      },
    },
    projectObj: {
      type: Object,
      default: () => {},
    },
    canArchive: {
      type: Boolean,
      default: false,
    },
    projectType: {
      type: String,
      default: 'Active',
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('profile', ['businessTypes']),
    profilePicture() {
      if (this.project.bannerImage instanceof File) {
        return URL.createObjectURL(this.project.bannerImage);
      }
      return this.project.bannerImage;
    },
    contactsList() {
      const { producersList, executivesList } = this.projectDependencies;
      return _.concat(producersList, executivesList);
    },
    // filteredSubscriptionBlends() {
    //   return this.projectDependencies.subscriptionBlends;
    // },
    isEditMode() {
      return Boolean(this.project.id);
    },
    title() {
      const type = this.formType === '1' ? 'Business' : 'Organization';
      if (this.projectObj.id) {
        return `Edit ${type}`;
      }
      return `Add ${type}`;
    },
    action() {
      if (this.projectObj.id) {
        return 'Update';
      }
      return 'Save';
    },
    bannerImage() {
      if (_.isObject(this.project.bannerImage)) {
        return this.project.bannerImage.name;
      }
      return 'Banner Image';
    },
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.project[field].$dirty) return errors;
        switch (field) {
          case 'name':
            if (!this.$v.project.name.required) { errors.push('Please provide valid business name'); }
            break;
          case 'firstName':
            if (!this.$v.project.firstName.required) { errors.push('Please provide valid first name'); }
            break;
          case 'lastName':
            if (!this.$v.project.lastName.required) { errors.push('Please provide valid last name'); }
            break;
          case 'businessTypeId':
            if (!this.$v.project.businessTypeId.required) { errors.push('Please select business vertical'); }
            break;
          case 'email':
            if (!this.$v.project.email.required) { errors.push('Please provide email'); }
            if (!this.$v.project.email.email) { errors.push('Please provide valid email'); }
            break;
          case 'businessId':
            if (!this.$v.project.businessId.required) { errors.push('Please select a customer account'); }
            break;
          // case 'subscriptionBlendIds':
          //   if (!this.$v.project.subscriptionBlendIds.required)
          //  { errors.push('Please select a subscription blend'); }
          //   break;
          default:
            break;
        }
        return errors;
      };
    },
  },
  data() {
    return ({
      pointOfContactRole: '',
      projectDependencies: {
        executivesList: [],
        editorsList: [],
        producersList: [],
        subscriptionBlends: [],
      },
      project: {
        name: '',
        date: new Date().toISOString().substr(0, 10),
        businessId: '',
        // subscriptionBlendIds: '',
        producerIds: '',
        executiveIds: [],
        editorIds: [],
        bannerImage: '',
        status: '1',
        pointOfContactId: null,
        businessTypeId: null,
        type: '',
      },
      statusItems: [
        { text: 'Active', value: '1' },
        { text: 'Archived', value: '2', disabled: !this.canArchive },
      ],
      loading: false,
      verticalUpdated: false,
    });
  },
  methods: {
    ...mapActions('project', ['addBusiness', 'updateBusiness']),
    ...mapActions('user', ['fetchUsers', 'fetchBusinesses']),
    ...mapActions('project', ['getSubscriptionBlends']),
    ...mapActions(['setNotification']),
    getOptimizedS3ImageURL,
    setProfilePicture(file) {
      this.project.bannerImage = file;
    },
    async setProducers(newval) {
      const contactId = _.get(this.project, 'pointOfContactId', null);
      const pointOfContactId = contactId ? JSON.parse(contactId) : null;
      this.project.producerIds = newval;
      if (this.pointOfContactRole === 'producer' && pointOfContactId
          && ((newval.length === 0) || (newval.length > 0
          && !newval.includes(pointOfContactId)))) {
        this.project.producerIds.push(pointOfContactId);
        this.setNotification({
          message: 'Cannot update point of contact!',
          type: 'error',
        }, { root: true });
      }
    },
    async setExecutives(newval) {
      const contactId = _.get(this.project, 'pointOfContactId', null);
      const pointOfContactId = contactId ? JSON.parse(contactId) : null;
      this.project.executiveIds = newval;
      this.selectedExectiveIds = await _.filter(newval,
        (val) => val !== pointOfContactId);
      if (this.pointOfContactRole === 'executive' && pointOfContactId
          && ((newval.length === 0) || (newval.length > 0
          && !newval.includes(pointOfContactId)))) {
        this.project.executiveIds.push(pointOfContactId);
        this.setNotification({
          message: 'Cannot update point of contact!',
          type: 'error',
        }, { root: true });
      }
    },
    async setContact(data) {
      if (data) {
        this.project.pointOfContactId = data;
        const producer = _.filter(this.project.producerIds, (val) => val === data);
        const executive = _.filter(this.project.executiveIds, (val) => val === data);
        const { producersList, executivesList } = this.projectDependencies;
        const isExective = _.includes(_.map(executivesList, (item) => item.value),
          data)
          ? 'executive' : '';
        const isProducer = _.includes(_.map(producersList, (item) => item.value),
          data)
          ? 'producer' : '';
        this.pointOfContactRole = isProducer || isExective;
        if (isProducer && producer.length === 0) {
          this.project.producerIds.push(data);
        }
        if (isExective && executive.length === 0) {
          this.project.executiveIds.push(data);
        }
      } else {
        this.project.pointOfContactId = null;
      }
    },
    async submitForm(e) {
      try {
        e.preventDefault();
        this.$v.$touch();
        if (this.$v.$invalid) throw new Error('Form is invalid');
        this.loading = true;
        const formData = new FormData();
        const project = {
          ...this.project,
          // subscriptionBlendIds: [this.project.subscriptionBlendIds],
        };
        _.map(project, (val, key) => {
          if (key === 'bannerImage' && !url(val)) {
            formData.append('bannerImage', val);
          } else if (val !== null) {
            formData.append(key, _.isObject(val) ? JSON.stringify(val) : val);
          }
        });
        if (!this.project.pointOfContactId) {
          formData.append('pointOfContactId', null);
        }
        formData.append('role', _.get(this.userDetails, 'role.name'));
        const filterStatus = _.find(this.statusItems,
          (status) => status.text === this.projectType);
        let result;
        if (this.project.id) {
          result = await this.updateBusiness({
            id: this.project.id,
            business: formData,
            isAdmin: this.isAdmin,
            status: filterStatus.value,
          });
        } else {
          result = await this.addBusiness({
            business: formData,
            isAdmin: this.isAdmin,
            status: filterStatus.value,
          });
        }
        if (result.success) {
          setTimeout(() => {
            this.$emit('close-modal');
          }, 1000);
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    async fetchProjectDependencies() {
      const [
        executivesList,
        producersList,
        editorsList,
        subscriptionBlends,
        businesses] = await Promise.all([
        // this.fetchUsers('client'),
        this.fetchUsers('executive'),
        this.fetchUsers('producer'),
        this.fetchUsers('editor'),
        this.getSubscriptionBlends(),
        this.fetchBusinesses(),
      ]);
      const nullNames = _.filter(businesses.business, 'name', '');
      const sortedBusiness = _.sortBy(nullNames, [(item) => item.name.toLowerCase()], ['asc']);
      this.projectDependencies.businesses = sortedBusiness;
      const executives = _.map(executivesList.users, (executive) => ({
        label: `${executive.firstName} ${executive.lastName ? executive.lastName : ''}`,
        value: executive.id,
      }));
      this.projectDependencies.executivesList = _.sortBy(executives, [(item) => item.label.toLowerCase()], ['asc']);
      const allProducers = _.filter(producersList.users, (user) => (!user.isTrainee
        || (this.project.producerIds && this.project.producerIds.includes(user.id))));
      const producers = _.map(allProducers, (producer) => ({
        label: `${producer.firstName} ${producer.lastName ? producer.lastName : ''}`,
        value: producer.id,
      }));
      this.projectDependencies.producersList = _.sortBy(producers, [(item) => item.label.toLowerCase()], ['asc']);

      const editors = _.map(editorsList.users, (editor) => ({
        label: `${editor.firstName} ${editor.lastName ? editor.lastName : ''}`,
        value: editor.id,
      }));
      this.projectDependencies.editorsList = _.sortBy(editors, [(item) => item.label.toLowerCase()], ['asc']);
      this.projectDependencies.subscriptionBlends = subscriptionBlends.subscriptionBlends;
      const isExective = _.includes(_.map(this.projectDependencies.executivesList,
        (item) => item.value),
      this.projectObj.pointOfContactId
        && JSON.parse(this.projectObj.pointOfContactId))
        ? 'executive' : '';
      const isProducer = _.includes(_.map(this.projectDependencies.producersList,
        (item) => item.value),
      this.projectObj.pointOfContactId
        && JSON.parse(this.projectObj.pointOfContactId))
        ? 'producer' : '';
      this.pointOfContactRole = isProducer || isExective;
    },
    deleteBannerImage() {
      this.project.bannerImage = '';
    },
  },
  validations: {
    project: {
      name: { required },
      firstName: { required },
      lastName: { required },
      email: { required, email },
      // subscriptionBlendIds: { required },
      businessTypeId: { required },
    },
  },
  mounted() {
    this.fetchProjectDependencies();
    this.project.type = this.formType;
    if (this.projectObj.id) {
      this.project = { ...this.projectObj };
      this.project.pointOfContactId = this.projectObj.pointOfContactId;
    } else if (!this.isAdmin) {
      if (_.get(this.userDetails, 'role.name', '') === 'executive') {
        this.project.executiveIds = [this.userDetails.id];
      } else if (_.get(this.userDetails, 'role.name', '') === 'producer') {
        this.project.producerIds = [this.userDetails.id];
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.add-organization-form {
  .scollable-div {
    overflow: auto;
    max-height: 500px;
    .base-input {
      margin-top: 6px;
    }
  }
  .title-header {
    border-bottom: 1px solid #E0E0E0;
    padding: 16px 21px 21px;
  }
  .organization-info {
    margin: 22px 25px;
  }
  .primary-info {
    border-top: 1px solid #F1F1F1;
    margin: 22px 25px 28px 25px !important;
    .text1 {
      padding-top: 22px;
      font-weight: 600;
      font-size: 19px;
      line-height: 23px;
      color: $charcoalBlack;
    }
    .text2 {
      padding-top: 11px;
      padding-bottom: 21px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #808080;
    }
  }
}
.v-form {
  .v-card__actions {
      padding: 0;
      justify-content: flex-end;
      border-top: 1px solid #E0E0E0;
      padding: 14px 27px 18px;
      .v-btn {
        border-radius: 6px;
        font-weight: 400;
        color: $secondary3;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        line-height: 24px;
        ::v-deep .v-btn__content {
          text-transform: uppercase;
          font-weight: 500;
          line-height: 19px;
          font-size: 16px;
        }
      }
      .submit {
        width: 112px;
        height: 40px;
        margin-left: 12px !important;
      }
      .cancel {
        width: 99px;
        height: 40px
      }
    }
  .banner-img {
    width: 88px;
    height: 88px;
    .profile-image {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    .border-dashed {
      object-fit: cover;
      background: #DEDEDE;
      border: 1px dashed $charcoalBlack;
    }
  }
  .organization-fields {
    .update-pic {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $secondary1;
      cursor: pointer;
    }
  }
}
  .auto-complete {
    margin-bottom: 0;
  }
  .heading {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: $charcoalBlack;
  }
  ::v-deep .v-picker.v-card {
      border-radius: 0 !important;
  }
  ::v-deep .input-field {
    font-family: $fontFamily1;
    height: inherit;
  }
  ::v-deep .v-text-field {
    margin-top: 0;
    padding-top: 0;
  }
  ::v-deep .v-select__slot {
    font-family: $fontFamily1;
  }
  ::v-deep .v-input {
    input:-webkit-autofill {
      -webkit-box-shadow:0 0 0 50px white inset;
    }
  }
  .upload-button {
    font-family: $fontFamily1;
    font-size: 12px;
    letter-spacing: -0.01px;
    color: $secondary1;
    span {
      cursor: pointer !important;
    }
  }
  ::v-deep .normalState .v-input__slot {
    border: 1px solid #E0E0E0 !important;
  }
  ::v-deep .nullState .v-input__slot {
    border: 1px solid #E0E0E0 !important;
  }
  ::v-deep .v-input {
  .v-input__control {
    .v-select__selection, input {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: $darkGray !important;
    }
    .v-input__slot:before {
      border-color: $border !important;
    }
  }
}
  ::v-deep .v-input__slot {
    min-height: 40px;
  }
  ::v-deep .type-selection {
    margin-top: 6px;
    .v-input__slot {
      border: solid 1px $border;
    }
  }
  ::v-deep .type-selection.error--text .v-input__slot {
    border: solid 1px #ff0808;
  }
</style>
