import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"d-flex align-center project-actions"},[_c('div',{staticClass:"d-flex justify-end card-body w-100"},[_c(VMenu,{attrs:{"bottom":"","left":"","offset-y":"","offset-x":"","content-class":"uploaded-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"menu-btn",attrs:{"dark":"","icon":"","height":"auto","width":"20"}},on),[_c(VIcon,{attrs:{"color":"black","dense":""}},[_vm._v("mdi-dots-horizontal")])],1)]}}])},[_c(VList,{staticClass:"actions-list"},_vm._l((_vm.params.actionItems),function(item){return _c(VListItem,{key:item.title,on:{"click":function($event){return _vm.params.clicked(item)}}},[_c(VListItemTitle,[(_vm.isImage(item.icon))?_c('img',{staticClass:"archive-icon",staticStyle:{"width":"24px","height":"14px"},attrs:{"src":require("@/assets/svg/theme/archive.svg")}}):_c(VIcon,{staticClass:"pr-2",attrs:{"small":""}},[_vm._v(_vm._s(item.icon))]),_c('span',{class:item.color && 'option-item-title'},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)}),1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }