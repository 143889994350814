<template>
  <div>
    <v-row class="ma-0 justify-space-between align-center mb-12">
      <PageTitle />
      <div class="pt-0" v-if="canAdd">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="admin" class="btn-purple mr-5" @click="createExp">
              Affiliate Business
            </v-btn>
            <v-btn
              class="addBtn btn-purple align-center"
              v-bind="attrs"
              v-on="on"
            >New
            <span class="ml-5 d-flex">
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 0L5 6L0 0H10Z" fill="white"/>
              </svg>
            </span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="$emit('new-project', '1')">
              <v-list-item-title>New Business</v-list-item-title>
            </v-list-item>
            <v-list-item @click="$emit('new-project', '2')">
                <v-list-item-title>New Organization</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-row>
    <v-row justify="between" class="ma-0">
      <v-row justify="start" class="ma-0">
      <div class="pt-0 filters-width business" v-if="canAccess">
        <v-select
          class="select-option"
          :items="[
            { text: 'All Businesses', value: true },
            { text: 'My Businesses', value: false },
          ]"
          label="Select Businesses"
          dense
          solo
          flat
          :value="showAll"
          :hide-details="true"
          @change="toggleProjects"
        ></v-select>
      </div>
      <div class="pt-0 pb-4 filters-width" v-if="this.getRole === 'admin'">
        <v-select
          class="select-option producer-filter"
          :clearable="true"
          :items="cp"
          :item-text="'name'"
          :item-value="'id'"
          label="Producer"
          dense
          solo
          flat
          value=""
          :hide-details="true"
          v-model="cpName"
        ></v-select>
      </div>
      <div class="pt-0 pb-4 filters-width">
        <v-select
          class="select-option"
          :items="items"
          label="Status"
          dense
          solo
          flat
          value="Active"
          :hide-details="true"
          v-model="projectType"
        ></v-select>
      </div>
      <div class="pt-0 pb-4 mr-3 filters-width">
        <v-select
          class="select-option"
          :items="types"
          label="Type"
          dense
          solo
          flat
          :hide-details="true"
          v-model="businessType"
        ></v-select>
      </div>
      </v-row>
      <div class="pt-0 pb-4" v-if="showSearch">
        <base-input
          class="search-input select-option"
          type="text"
          solo
          dense
          flat
          clearable
          @click:clear="$emit('search-change', '')"
          :hide-details="true"
          prepend-inner-icon="search"
          placeholder="Search"
          v-model="search"
          @input="$emit('search-change', search)"
        >
        </base-input>
      </div>
    </v-row>
    <v-container
          fluid
          v-if="openAffiliate"
        >
          <Modal
            persistent
            class="sweep-container"
            content-class="new-user-form"
            :modal="openAffiliate"
            width="480px"
            height="899px"
          >
          <ExpBusiness
            :credits="credits"
            @closemodal="openAffiliate = false"
          />
          </Modal>
        </v-container>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import BaseInput from '@/components/common/BaseInput';
import PageTitle from '@/components/common/PageTitle';
import Modal from '@/components/common/Modal';
import ExpBusiness from '@/components/projects/ExpBusiness';

export default {
  components: {
    BaseInput,
    PageTitle,
    Modal,
    ExpBusiness,
  },
  props: {
    canAdd: {
      type: Boolean,
      default: false,
    },
    canAccess: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return ({
      openAffiliate: false,
      items: ['Active', 'Archived'],
      types: ['All Types', 'Business', 'Organization'],
      businessType: 'All Types',
      projectType: 'Active',
      search: '',
      showAll: true,
      cp: [],
      cpName: 0,
    });
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    getRole() {
      return _.get(this.userDetails, 'role.name');
    },
    admin() {
      return ['admin'].includes(this.getRole);
    },
  },
  watch: {
    projectType(newValue) {
      this.$emit('project-type', newValue);
    },
    businessType(newValue) {
      this.$emit('business-type', newValue);
    },
    cpName(val) {
      this.$emit('filter-cp', val);
    },
  },
  methods: {
    ...mapActions('user', ['fetchAdminUsersList']),
    createExp() {
      this.openAffiliate = true;
    },
    toggleProjects() {
      this.showAll = !this.showAll;
      this.$emit('filter-projects', this.showAll);
    },
  },
  async mounted() {
    if (['admin'].includes(this.getRole)) {
      let { users } = await this.fetchAdminUsersList({ roleName: 'producer' });
      users = _.groupBy(users, (user) => user.role.name);
      users = _.concat(users.producer, users.executive);
      _.map(users, (val) => {
        if (val) {
          this.cp.push({ id: val.id, name: `${val.firstName} ${val.lastName}` });
        }
      });
    }
    if (['producer'].includes(this.getRole)) {
      this.showAll = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.filters-width ::v-deep .v-select__selections {
  .v-select__selection {
    max-width: none !important;
  }
  input {
    max-width: 0;
  }
}
  .producer-filter {
    max-width: 357px;
    font-family: $fontFamily1;
    ::v-deep {
      .v-input__control .v-input__slot .v-select__slot {
        min-width: 100px;
      }
    }
  }
  .addBtn {
    width: 93px !important;
    height: 40px !important;
    .v-btn__content {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
    }
  }
  .select-option {
    ::v-deep label {
      color: $charcoalBlack !important;
    }
    ::v-deep .v-select__selections, .v-select-list {
        font-family: $fontFamily1;
        letter-spacing: -0.28px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
      }
  }
  ::v-deep .v-input__control {
    border-radius: 6px !important;
  }
  ::v-deep .v-list-item__title{
    font-size:14px !important;
    font-family: $fontFamily1 !important;
    font-weight:400 !important;
    line-height: normal !important;
  }
  .search-input {
    font-family: $fontFamily1 !important;
    border: 1px solid #E0E0E0;
    border-radius: 3px;
    max-width: 100%;
    ::v-deep .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
      min-height: 38px !important;
    }
    ::v-deep {
      .input-field {
        height: auto;
      }
      .v-input__control > .v-input__slot {
        border-radius: 6px !important;
        box-shadow: 0 7px 64px 0 rgba(0, 0, 0, 0.07) !important;
        min-height: 40px !important;
      }
    }
  }
</style>
