<template>
  <div class="project-list h-100">
    <Loader v-if="fetching" class="h-50" />
    <grid-list
      v-if="!fetching && items && items.length > 0"
      :headers="headers"
      :items="items"
      :fetching="fetching"
      :search="search"
      @rowClicked="(row) => navigate(row)"
    >
    </grid-list>
    <div v-else-if="!fetching" class="empty-projects-container">
      <v-img :src="require('@/assets/svg/theme/welcome.svg')" contain class="empty-theme-img" />
      <div class="empty-text" v-if="search === ''">
        <span v-if="cpName !== 0"
          >Sorry, no results found! Please check the filters applied, spelling or try searching for
          something else
        </span>
        <span v-else>
          Uh oh. You don't have any {{ projectType.toLowerCase() }} projects at this time
        </span>
      </div>
      <div class="empty-text" v-else>
        Sorry, no results found! Please check the spelling or try searching for something else
      </div>
    </div>
    <div class="info-container" v-if="showInfo">
      <div class="card-info" v-click-outside="onClickOutsideInfo">
        <v-card tile flat outlined>
          <v-card-title class="pt-2 pb-0 pl-2 pr-0 text-capitalize notranslate">
            {{ selectedBusiness.name }} <v-spacer></v-spacer>
            <v-icon small class="pr-1" @click="showInfo = false">mdi-close</v-icon>
          </v-card-title>
          <v-divider class="ml-2 mr-6"> </v-divider>
          <v-card-text class="pa-2 font-weight-medium black--text">
            <v-row class="ma-0">
              <v-col cols="5"> Date: </v-col>
              <v-col cols="7">{{ moment(selectedBusiness.createdAt).format('MM/DD/YYYY') }}</v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col cols="5"> Customer: </v-col>
              <v-col cols="7" class="name notranslate">{{ clientName }}</v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col cols="5"> Blend: </v-col>
              <v-col cols="7" class="notranslate">{{ projectBlend }}</v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col cols="5"> CP: </v-col>
              <v-col cols="7" class="name notranslate">{{ producers }}</v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col cols="5"> PM: </v-col>
              <v-col cols="7" class="name notranslate">{{ executives }}</v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import { lookup } from 'mime-types';
import vClickOutside from 'v-click-outside';
import { getOptimizedS3ImageURL } from '@/helpers/';
import businessesIcon from '@/assets/svg/businesses.svg';
import organizationsIcon from '@/assets/svg/organisations.svg';
import GridList from '@/components/organizations/GridList';
import BtnActions from '@/components/projects/BtnActions';
import Loader from '@/components/common/Loader';

export default {
  components: {
    GridList,
    Loader,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      fetching: false,
      showInfo: false,
      selectedBusiness: {},
    };
  },
  props: {
    projectType: {
      type: String,
      default: 'Active',
    },
    selectedBusinessType: {
      type: String,
      default: 'All Types',
    },
    search: {
      type: String,
      default: '',
    },
    canUpdate: {
      type: Boolean,
      default: false,
    },
    showAllProjects: {
      type: Boolean,
      default: true,
    },
    cpName: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    ...mapActions('project', ['getUserBusinesses', 'updateProject']),
    ...mapActions('project', ['assignProject', 'updateBusinessStatus']),
    getOptimizedS3ImageURL,
    moment,
    navigate(row) {
      const { id } = row.data.business.project;
      this.$router.push({
        path: `/project_overview/${id}?businessId=${row.data.business.id}&activeTab=brief`,
      });
    },
    onClickOutsideInfo() {
      this.showInfo = false;
    },
    assignToProject(item) {
      this.assignProject({
        id: item.project.id,
        status: item.status,
      });
    },
    projectUpdate(item, status) {
      this.updateBusinessStatus({
        business: { ...item, ...{ status }, role: _.get(this.userDetails, 'role.name') },
        status: status === '2' ? '1' : '2',
      });
    },
    actionItemClicked(clickItem, business) {
      const { type } = clickItem;
      this.selectedBusiness = business;
      if (type === 1) {
        this.showInfo = true;
      }
      if (type === 2) {
        this.assignToProject(business);
      }
      if (type === 3) {
        this.$emit('onEdit', business);
      }
      if (type === 4) {
        this.projectUpdate(business, '2');
      }
      if (type === 5) {
        this.projectUpdate(business, '1');
      }
    },
    canAssign(business) {
      return (['producer', 'executive'].includes(_.get(this.userDetails, 'role.name')) && !business.assigned);
    },
    async setUserProjects(projectType) {
      this.fetching = true;
      if (projectType === 'Archived') {
        await this.getUserBusinesses(2);
      } else {
        await this.getUserBusinesses(1);
      }
      this.fetching = false;
    },
    getFilteredProjectsByCp(projectsData) {
      const projects = projectsData;
      const data = [];
      if (this.cpName) {
        _.map(projects, (proj) => {
          _.map(proj.projectTeam, (team) => {
            if (team.id === this.cpName) {
              data.push(proj);
            }
          });
        });
        return data;
      }
      return projects;
    },
    businessColumn2() {
      return {
        headerName: 'business',
        headerClass: 'name-column',
        field: 'name',
        sortable: true,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        filter: true,
        cellClass: 'notranslate text-wrap  justify-start',
        width: 100,
        cellRenderer: (params) => `<div class="d-flex align-center">
        <div class="d-flex" style="padding-right: 21px;">
          <div style="width: 40px; height: 40px;">
            <img src=${getOptimizedS3ImageURL(this.cardImage(params.data.business), {
    width: 250,
    height: 250,
  })} style="width: 100%; height: 100%; object-fit: contain;"/>
          </div>
        </div>
        <div
        style="overflow-x: hidden !important;text-overflow: ellipsis !important;
        padding-left: 20px !important;">
        <span style="font-weight: 400;
                      font-size: 16px;
                      line-height: 22px;
                      color: #8066FF;
                      ">${params.value}</span>
                      <div>
                      <span>
            ${params.data.business.type === '1' ? 'Business' : 'Organization'}
          </span></div>
          </div>
                      </div>`,
      };
    },
    businessColumn() {
      return {
        headerName: 'business',
        headerClass: 'name-column',
        field: 'name',
        sortable: true,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        filter: true,
        cellClass: 'notranslate text-wrap  justify-start',
        width: 100,
        cellRenderer: (params) => `<div class="d-flex align-center">
        <div class="d-flex" style="padding-right: 21px;">
          <div style="width: 40px; height: 40px;">
            <img src=${getOptimizedS3ImageURL(this.cardImage(params.data.business), {
    width: 250,
    height: 250,
  })} style="width: 100%; height: 100%; object-fit: contain;"/>
          </div>
        </div>
        <span style="font-weight: 400;
                      font-size: 16px;
                      line-height: 22px;
                      color: #8066FF;">${params.value}</span></div>`,
      };
    },
    businessTypeColumn() {
      return {
        headerName: 'Type',
        field: 'type',
        sortable: true,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        filter: true,
        cellClass: 'notranslate text-wrap  justify-start',
        width: 100,
      };
    },
    businessVerticalColumn() {
      return {
        headerName: 'Business Vertical',
        field: 'businessType',
        sortable: true,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        filter: true,
        cellClass: 'notranslate text-wrap  justify-start',
        width: 100,
      };
    },
    btnActionsColumn() {
      const $this = this;
      return {
        headerName: '',
        cellStyle: { display: 'flex', justifyContent: 'flex-end' },
        field: 'id',
        width: 10,
        cellRendererFramework: BtnActions,
        cellRendererParams: (params) => ({
          actionItems: this.menuList(params.data.business, _.get(params.data.business, 'status')),
          clicked(data) {
            $this.actionItemClicked(data, params.data.business);
            return false;
          },
        }),
      };
    },
    menuList(business, status) {
      const list = [
        // option to show business info
        // {
        //   type: 1,
        //   icon: 'mdi-information-outline',
        //   title: 'Info',
        // },
      ];
      if (this.canAssign(business) && !['admin'].includes(this.userDetails.role.name)) {
        list.push({
          type: 2,
          icon: 'mdi-plus-circle-outline',
          title: 'Add to my businesses',
        });
      }
      if (!this.canAssign(business) || ['executive', 'admin', 'producer'].includes(this.userDetails.role.name)) {
        list.push({
          type: 3,
          icon: 'mdi-square-edit-outline',
          title: 'Edit',
        });
        if (status && status.toString() !== '2') {
          list.push({
            type: 4,
            icon: '@/assets/svg/theme/archive.svg',
            title: 'Archive Business',
            color: true,
          });
        } else {
          list.push({
            type: 5,
            icon: 'mdi-delete-restore',
            title: 'Active Business',
          });
        }
      }
      return list;
    },
    isFileImage(format) {
      return format && ['jpg', 'png', 'jpeg', 'gif', 'svg'].includes(format.split('/')[1]);
    },
    validImage(business) {
      return _.get(business, 'bannerImage') && this.isFileImage(lookup(_.get(business, 'bannerImage')));
    },
    cardImage(business) {
      const defaultIcon = business.type === '1' ? businessesIcon : organizationsIcon;
      return this.validImage(business) ? getOptimizedS3ImageURL(
        _.get(business, 'bannerImage'),
        { width: 500, height: 500 },
      ) : defaultIcon;
    },
    getBusinessType(id) {
      const vertical = _.find(this.businessTypes, (type) => type.id === id);
      return _.get(vertical, 'name', '');
    },
  },
  computed: {
    ...mapGetters('project', ['businesses']),
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('profile', ['businessTypes']),
    headers() {
      const $this = this;
      const items = [];
      if ($this.$vuetify.breakpoint.mdAndUp) {
        items.push($this.businessColumn());
        items.push($this.businessTypeColumn());
        items.push($this.businessVerticalColumn());
      }
      if ($this.$vuetify.breakpoint.smAndDown) {
        items.push($this.businessColumn2());
      }
      if (this.enableAccess) {
        items.push($this.btnActionsColumn());
      }
      return items;
    },
    enableAccess() {
      return ['admin', 'executive', 'producer'].includes(_.get(this.userDetails, 'role.name'));
    },
    currentBusinesses() {
      let projects = this.showAllProjects
        ? this.getFilteredProjectsByCp(this.businesses)
        : this.getFilteredProjectsByCp(_.filter(this.businesses, 'assigned'));
      this.$emit('filters-change', projects.length);
      if (this.search.length > 2) {
        const data = _.filter(projects, (project) => project.name);
        projects = _.filter(data,
          (c) => _.includes(c.name.toLowerCase(), this.search.toLowerCase()));
      }
      return projects;
    },
    items() {
      const org = this.selectedBusinessType === 'Organization' ? '2' : null;
      const type = this.selectedBusinessType === 'Business' ? '1' : org;
      const businesses = type ? _.filter(this.currentBusinesses,
        (business) => business.type === type)
        : this.currentBusinesses;
      return _.map(businesses, (business) => ({
        id: business.id,
        business,
        name: business.name,
        businessType: this.getBusinessType(business.businessTypeId),
        type: business.type === '1' ? 'Business' : 'Organization',
      }));
    },
    clientName() {
      return _.get(this.selectedBusiness, 'name', '');
    },
    projectBlend() {
      const blends = _.map(this.selectedBusiness.subscriptionBlends, (object) => _.get(object, 'name'));
      return blends.join(', ');
    },
    producers() {
      const producerNames = _.map(
        this.selectedBusiness.producers,
        (object) => `${_.get(object, 'firstName')} ${_.get(object, 'lastName')}`,
      );
      return producerNames.join(', ');
    },
    executives() {
      const executiveNames = _.map(
        this.selectedBusiness.executives,
        (object) => `${_.get(object, 'firstName')} ${_.get(object, 'lastName')}`,
      );
      return executiveNames.join(', ');
    },
  },
  mounted() {
    this.setUserProjects(this.projectType);
  },
  watch: {
    projectType(newValue, oldValue) {
      if (oldValue !== newValue) {
        this.setUserProjects(newValue);
      }
    },
    showAllProjects() {
      this.setUserProjects(this.projectType);
    },
  },
};
</script>

<style scoped lang="scss">
.info-container {
  width: 100%;
  position: absolute;
  right: 0%;
  top: 20%;
  display: flex;
  justify-content: center;
}
.card-info {
  box-shadow: 0 2px 2px 0 $silver;
  font-family: $fontFamily1;
  max-height: 110%;
  width: 25%;
  overflow-y: auto;
  .v-card__title,
  .v-card__text {
    font-size: 12px !important;
  }
  .v-card__title {
    line-height: normal;
  }
  .col-5,
  .col-7 {
    padding: 0;
  }
  .col-7 {
    padding-left: 5px;
  }
  .name {
    font-weight: normal;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.project-list {
  min-height: 1520px;
  max-height: calc(100vh - 160px);
  overflow: auto;
  ::v-deep.page-loader-div {
    height: 500px !important;
  .project-container {
    background: white;
    height: 100%;
  }
}
}
.empty-projects-container {
  .empty-theme-img {
    text-align: center;
    margin: 40px auto 0;
    width: 500px;
  }
  .empty-text {
    font-size: 24px;
    text-align: center;
    padding: 20px;
  }
}
::v-deep .v-btn__content {
  font-family: $fontFamily1 !important;
  font-weight: 300 !important;
}
@media (max-width: 600px) {
  .project-list {
    max-height: calc(100vh - 200px);
    ::v-deep.ag-header-cell .ag-header-cell-label {
      margin-left: 80px !important;
    }
  }
}
@media (max-width: 700px) {
  .empty-projects-container {
    .empty-theme-img {
      width: 300px;
    }
  }
}
</style>
