<template>
  <div class="h-100 bg-white">
    <div class="main-container h-100">
    <ProjectsHeader
      @new-project="openBusinessForm"
      @project-type="projectTypeHandler"
      @business-type="businessTypeFilter"
      @search-change="searchChange"
      @filter-projects="filterProjects"
      @filter-cp="filterCp"
      :showSearch="filter"
      :canAccess="canAccess"
      :canAdd="canAdd"
    ></ProjectsHeader>
    <BusinessesList
      :projectType="projectType"
      :selectedBusinessType="selectedBusinessType"
      :cpName="cpName"
      :search="searchValue"
      :canUpdate="canUpdate"
      @filters-change="filtersValue"
      @onEdit="editProject"
      :showAllProjects="canAccess ? showAllProjects : true"
    >
    </BusinessesList>

    <v-container
      fluid
      v-if="modal"
    >
    <Modal
      persistent
      content-class="main-modal"
      :modal="modal"
      width="600"
      >
        <AddBusiness
        :projectType="projectType"
          :formType="newBusinessType"
          @close-modal="closeForm"
          :projectObj="selectedProject"
          :canArchive="true"
        />
      </Modal>
    </v-container>
  </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import Modal from '@/components/common/Modal';
import ProjectsHeader from '../components/projects/ProjectsHeader';
import BusinessesList from '../components/projects/BusinessesTable';
import AddBusiness from '../components/projects/AddBusiness';

export default {
  name: 'Projects',
  components: {
    Modal,
    ProjectsHeader,
    BusinessesList,
    AddBusiness,
  },
  computed: {
    ...mapGetters(['modal']),
    ...mapGetters('user', ['userDetails']),
    canAccess() {
      return _.includes(['executive', 'producer', 'editor'], this.role);
    },
    canAdd() {
      return _.includes(['executive', 'producer', 'admin'], this.role);
    },
    canUpdate() {
      return _.includes(['executive', 'producer', 'admin'], this.role);
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
  },
  data() {
    return ({
      projectType: 'Active',
      selectedBusinessType: 'All Types',
      newBusinessType: '',
      searchValue: '',
      selectedProject: {},
      showAllProjects: true,
      filter: false,
      cpName: 0,
    });
  },
  methods: {
    ...mapActions(['setModal']),
    ...mapActions('profile', ['getClientOptions']),
    openBusinessForm(type) {
      this.newBusinessType = type;
      this.setModal(true);
    },
    projectTypeHandler(projectType) {
      this.projectType = projectType;
    },
    businessTypeFilter(businessType) {
      this.selectedBusinessType = businessType;
    },
    searchChange(value) {
      const val = !_.isEmpty(value) ? value : '';
      this.searchValue = val;
    },
    filtersValue(value) {
      if (value > 0) {
        this.filter = true;
      } else {
        this.filter = false;
      }
    },
    editProject(business) {
      this.newBusinessType = _.get(business, 'type', '');
      this.selectedProject = { ...business };
      const users = this.selectedProject.userBusiness.map((ub) => ub.user);
      const owner = users.find((user) => user && user.isPrimaryOwner) || {};
      this.selectedProject.firstName = owner.firstName || '';
      this.selectedProject.lastName = owner.lastName || '';
      this.selectedProject.email = owner.email || '';
      this.selectedProject.producerIds = _.map(business.producers, 'id');
      this.selectedProject.executiveIds = _.map(business.executives, 'id');
      this.selectedProject.editorIds = _.map(this.selectedProject.editors, 'id');
      this.selectedProject.businessTypeId = _.get(business, 'businessTypeId', null);
      this.selectedProject.deliverables = _.get(business, 'project.deliverables');
      this.selectedProject.valueProps = _.get(business, 'project.valueProps');
      this.selectedProject.brandProfile = _.get(business, 'project.brandProfile');
      this.selectedProject.status = _.get(business, 'status').toString();
      this.selectedProject.subscriptionBlendIds = _.get(this.selectedProject, 'subscriptionBlends[0]', {}).id;
      this.setModal(true);
    },
    async closeForm() {
      this.setModal(false);
      this.selectedProject = {};
    },
    filterProjects(val) {
      this.showAllProjects = val;
    },
    filterCp(val) {
      this.cpName = val;
    },
  },
  async mounted() {
    await this.getClientOptions();
    if (['producer'].includes(this.role)) {
      this.showAllProjects = false;
    }
  },
};
</script>
