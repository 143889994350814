<template>
  <span class="d-flex align-center project-actions">
    <div class="d-flex justify-end card-body w-100">
      <v-menu bottom left offset-y offset-x content-class="uploaded-content">
        <template v-slot:activator="{ on }">
          <v-btn dark icon height="auto" width="20" v-on="on" class="menu-btn">
            <v-icon color="black" dense>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>
        <v-list class="actions-list">
          <v-list-item
          v-for="item in params.actionItems"
          :key="item.title"
          @click="params.clicked(item)">
            <v-list-item-title>
              <img
                src="@/assets/svg/theme/archive.svg"
                class="archive-icon"
                style="width: 24px; height: 14px"
                v-if="isImage(item.icon)"
              />
              <v-icon small class="pr-2" v-else>{{ item.icon }}</v-icon>
              <span :class="item.color && 'option-item-title'"> {{ item.title }} </span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </span>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  methods: {
    isImage(image) {
      if (image.indexOf('.svg') >= 0) {
        return true;
      }
      return false;
    },
  },
});
</script>

<style lang="scss" scoped>
.uploaded-content {
  .option-item-title {
    color: #FF0000;
  }
  .v-list {
      border-radius: 0;
      padding: 0;
      .v-list-item {
        padding: 10px 20px !important;
        border-bottom: 1px solid #dde2eb;
        .v-list-item__title {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: $charcoalBlack;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
  .menu {
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 5px;
    z-index: 7;
  }
  .menu-btn {
    width: 10px;
    margin-right: 5px;
  }
  .v-menu__content {
    border-radius: 0;
  }
}
</style>
